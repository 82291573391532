<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <destination-form
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un destination') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </destination-form>
    </v-dialog>
    <!-- destination total card -->

    <!-- list filters -->
    <v-row justify="center" >
      <v-col cols="12" md="6" lg="6">
        <v-card>
          <v-card-title>
            {{ $t("Destinations") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
          </v-card-title>
          <v-divider class="mt-4"></v-divider>

          <!-- actions -->
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <!-- search -->
            <v-text-field
              v-model="searchQuery"
              :placeholder="$t('Rechercher')"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>

            <v-spacer></v-spacer>

            <div class="d-flex align-center flex-wrap">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                @click.stop="openDialog(false)"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                <span>{{ $t("Ajouter un Destination") }}</span>
              </v-btn>

              <!--          <v-btn
                color="secondary"
                outlined
                class="mb-4"
              >
                <v-icon
                  size="17"
                  class="me-1"
                >
                  {{ icons.mdiExportVariant }}
                </v-icon>
                <span>Export</span>
              </v-btn>-->
            </div>
          </v-card-text>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            class="fixed-table"
            dense
            :headers="tableColumns"
            :items="items"
            :options.sync="options"
            :page.sync="page"
            :server-items-length="totalItems"
            :loading="loading"
            hide-default-footer
            show-select
            :loading-text="$t('Chargement des éléments')"
            :no-data-text="$t('Pas de données')"
            :no-results-text="$t('Aucun résultat')"
            @page-count="pageCount = $event"
          >
            <!-- actions -->
            <template #[`item.actions`]="{item}">
              <v-btn
                icon
                small
              >
                <v-icon
                  size="20"
                  @click="openDialog(item)"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="!$can('delete', 'Printer') || !item.can_delete"
                :title="item.can_delete?'Supprimer':'Impossible de supprimer'"
                icon
                small
                color="error"
              >
                <v-icon
                  size="20"
                  color="error"
                  @click="confirmDelete(item)"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-text class="pt-2">
            <v-row>
              <v-col
                lg="5"
                cols="5"
              >
                <rows-per-page :options="options" />
              </v-col>

              <v-col
                lg="7"
                cols="7"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="page"
                  total-visible="6"
                  :length="pageCount"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import DestinationForm from './DestinationForm.vue'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'

export default {
  components: {
    RowsPerPage,
    DestinationForm,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'destination'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchItems,
    } = controller()

    return {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
